.footer-media {
    display: flex;
    justify-content: flex-start;
    padding-top: 48px;
}

.footer-icon {
    margin: 0 0 12px 24px;
}

.footer-icon svg {
    fill: var(--confessions-grayhover);
    transition: fill 0.5s;
}

.footer-icon svg:focus, .footer-icon svg:hover, .footer-icon svg:active {
    fill: var(--confessions-gray);
}

.copyright {
    font-size: 12px;
    color: var(--confessions-grayhover);
    margin: 0 0 24px 24px;
}