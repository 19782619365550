.episodePage-container {
    display: flex;
    justify-content: center;
}

.episode-container {
    margin-top: 24px;
    padding: 0 64px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 800px;
    min-width: 300px;
}

.episodePage-title {
    font-size: 18pt;
    text-align: center;
    margin: 12px 0 24px 0;
}

.episodePage-quote {
    font-size: 16pt;
    font-style: italic;
    text-align: center;
}

.episodePage-cw {
    text-align: center;
    margin-bottom: 8px;
    margin-bottom: 24px;
}

.episodePage-text {
    font-family: 'Inter', Arial, Helvetica, sans-serif;
    text-align: center;
    padding: 24px 0;
}

.episode-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.episode-video-container {
    position: relative;
    width: 100%;
    height: 100%;
    padding-bottom: 56.25%;
    z-index: 0;
}

.episode-link {
    padding-top: 18px;
    color: var(--confessions-gray);
    font-size: 14pt;
    text-decoration: none;
}

.episode-link:hover {
    color: var(--confessions-jade);
    transition: 0.5s;
    text-decoration: underline;
}