.notFound-container {
    display: flex;
    align-items: center;
    height: calc(100vh - 200px);
    justify-content: center;
    flex-direction: column;
}

.notFound-text {
    font-size: 36px;
    color: var(--confessions-gray);
    padding-bottom: 24px;
}

.button:hover{
    cursor: pointer;
    transition: 0.2s;
    text-shadow: 0.5px 0.5px var(--confessions-grayhover);
    cursor: pointer;
}

.button {
    color: var(--confessions-gray);
    font-size: 12pt;
    text-decoration: none;
}

.button-img {
    opacity: 0.6;
}

.container:hover .button-img {
    opacity: 1;
    transition: 0.2s;
}

