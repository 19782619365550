.page-body {
    background-color: var(--confessions-beige);
}

.nav {
    position: sticky;
    top: 0px;
    transition: transform 0.4s ease-in-out;
    z-index: 1;
}

.nav.nav-bar--hidden {
    transform: translateY(-100%);
    transition: all 0.4s ease-in-out;
    z-index: 1;
}

.header-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 24px 0 12px;
    background-color: var(--confessions-beige);
}

.header-title-container {
    display: flex;
    flex-direction: column;
    align-items: top;
    justify-content: center;
}

.header-link-container {
    margin: 48px 16px 0 0;
    margin-left: -16px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-self: flex-start;
    justify-content: flex-end;
}

.header-logo-link {
    text-decoration: none;
}

.header-logo-img {
    max-width: 300px;
    margin-top: 8px;
}

.header-link {
    padding-left: 36px;
    font-size: 14pt;
    color: var(--confessions-grayhover);
    text-decoration: none;
}

.header-link-active {
    text-decoration: underline;
}

.header-link:hover {
    color: var(--confessions-gray);
    transition: 0.5s;
    text-decoration: underline;
}

@media (max-width: 860px) {
    .header-link-container {
        display: none;
    }
}

@media (max-width: 600px) {
    .header-logo-img {
        max-width: 200px;
        margin: 12px 0;
    }
}
