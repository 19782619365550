.trailer-header {
    color: var(--confessions-gray);
    text-align: left;
    padding: 24px;
    font-size: 24pt;
}

.trailer-header-container {
    display: flex;
}

.container {
    display: flex;
    justify-content: center;
    padding: 24px 24px;
}

.trailer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    max-width: 800px;
    min-width: 300px;
}

.trailer-video-container {
    position: relative;
    width: 100%;
    height: 100%;
    padding-bottom: 56.25%;
    z-index: 0;
}

.trailer-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}