.episode-block-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    direction: rtl;
    max-width: 800px;
}

.episode-block {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.episode-block:hover {
    cursor: pointer;
}

.episode-still {
    object-fit: cover;
    max-width: 70%;
    background-color: var(--confessions-jade);
    transition: 0.5s ease-in-out;
}

.episode-block:hover .episode-still {
    max-width: 100%;
}

.episode-name {
    font-size: 20pt;
    color: var(--confessions-grayhover);
    max-width: 70%;
    margin-bottom: 16px;
    transition: 0.5s ease-in-out;
}

.episode-quote {
    font-size: 16pt;
    font-style: italic;
    color: var(--confessions-grayhover);
    max-width: 70%;
    transition: 0.5s ease-in-out;
    margin-top: 8px;
    text-align: right;
}

.episode-block:hover .episode-name {
    font-size: 24pt;
    transition: 0.5s ease-in-out;
    max-width: 100%;
    color: var(--confessions-gray);
}

.episode-block:hover .episode-quote {
    font-size: 20pt;
    transition: 0.5s ease-in-out;
    max-width: 100%;
    color: var(--confessions-gray);
}

@media (max-width: 800px) {
    .episode-still {
        max-width: 100%;
    }
    .episode-name {
        max-width: 100%;
    }
    .episode-quote {
        max-width: 100%;
        text-align: left;
    }
    .episode-block:hover .episode-name {
        font-size: 20pt;
    }
    .episode-block:hover .episode-quote {
        font-size: 16pt;
    }
    .episode-block-wrapper {
        direction: ltr;
        margin: 0 24px;
    }
}